<script setup>
import { onMounted, ref, computed } from "vue";
import pagination from "@/components/common/pagination.vue";
import { useStore } from "vuex";
import InputText from "@/components/common/InputText.vue";
import spinnerLoading from "@/components/common/spinner-loading.vue";
import ticketCadencia from "@/components/dashboard/admin/cadencia/ticket-cadencia.vue";
import DetalhesCadencia from "@/components/dashboard/admin/cadencia/cadencia.vue";
import criaCadencia from "@/components/dashboard/admin/cadencia/cria-cadencia.vue";

const loading = computed(() => {
  return store.state.loading;
});

const store = useStore();
const currentRoute = ref("list");
const page = ref(1);
const currentCadence = ref({});
const pesquisa = ref("");
const sortBy = ref("Nome");
const selectedEmpresa = ref(0);

const empresas = computed(() => {
  return store.state.empresaModule.empresas;
});

const cadencias = computed(() => {
  return store.state.cadenceModule.cadences;
});

const companys = computed(() => {
  return store.state;
})

const listaCadencias = computed(() => {
  var final = null;
  let allCadences = cadencias.value;
  if (selectedEmpresa.value != 0) {
    allCadences = cadencias.value.filter((cadencia) => {
      return cadencia.empresa_id == selectedEmpresa.value;
    });
  }
  if (pesquisa.value == "") {
    final = allCadences;
  } else {
    final = allCadences.filter((cadencia) => {
      let currentSort = null;
      switch (sortBy.value) {
        case "Nome":
          currentSort = cadencia.name
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;

        case "Status":
          currentSort = cadencia.status
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;

        default:
          currentSort = cadencia.name
            .toLowerCase()
            .includes(pesquisa.value.toLowerCase());
          break;
      }

      return currentSort;
    });
    page.value = 1;
  }
  let finalCadencias = final
    .sort(compararDatas)
    .slice(page.value * 10 - 10, page.value * 10);
  return { result: finalCadencias, length: final.length };
});

const isCadenciasEmpty = computed(() => {
  return cadencias.value.length == 0 ? true : false;
});
onMounted(() => {
  try {
    store.dispatch("cadenceModule/getAll");
    store.dispatch("empresaModule/getAllEmpresas");
  } catch (e) {
    console.log(e);
  }
});

const nextPage = (newPage) => {
  page.value = newPage;
};

const getById = (item) => {
  currentRoute.value = "detail";
  currentCadence.value = item;
};

const createCadencia = () => {
  currentRoute.value = "create";
};

const confirmDelete = (item) => {
  store.commit("cadenceModule/SET_SELECTED_CADENCE", item);
  store.commit("cadenceModule/SET_DELETE_POPUP", true);
};

function voltar() {
  currentRoute.value = "list";
  currentCadence.value = {};
}
function compararDatas(a, b) {
  const dataA = new Date(a.createdAt);
  const dataB = new Date(b.createdAt);
  return dataB - dataA;
}
</script>

<template>
  <div style="width: 100%" v-bind:class="String">
    <spinner-loading v-if="loading"></spinner-loading>
    <div v-else-if="currentRoute == 'list'" class="contedo-helpdesk-container1">
      <div class="contedo-helpdesk-header">
        <div class="contedo-helpdesk-container2">
          <div class="contedo-helpdesk-display">
            <span class="contedo-helpdesk-text titulo">Cadências</span>
          </div>
          <div v-if="!isCadenciasEmpty" class="contedo-helpdesk-pesquisa">
            <input-text
              label="Pesquisar"
              icon="search-outline"
              v-model:value="pesquisa"
            ></input-text>
          </div>
          <div class="contedo-helpdesk-actions">
            <!-- <div v-if="!isCadenciasEmpty" class="contedo-helpdesk-container4">
              <select
                v-model="selectedEmpresa"
                class="contedo-helpdesk-select Optionmenu"
              >
                <option :value="0">Todas as empresas</option>
                <option
                  v-for="empresa in empresas"
                  :key="empresa.id"
                  :value="empresa.id"
                >
                  {{ empresa.name }}
                </option>
              </select>
            </div> -->
            <div v-if="!isCadenciasEmpty" class="contedo-helpdesk-container4">
              <select
                v-model="sortBy"
                class="contedo-helpdesk-select Optionmenu"
              >
                <option value="Nome">Nome</option>
                <option value="Status">Status</option>
              </select>
            </div>
            <button @click="createCadencia" class="contedo-helpdesk-boto">
              <span class="contedo-helpdesk-text01 textbutton"
                >+ Nova cadência</span
              >
            </button>
          </div>
        </div>
      </div>
      <!-- LISTA DE CADÊNCIAS -->
      <spinnerLoading v-if="loading"></spinnerLoading>
      <emptyHelpdesk v-else-if="isCadenciasEmpty && !loading"></emptyHelpdesk>
      <div v-else class="contedo-helpdesk-clientlist">
        <ticket-cadencia
          @update:confirmeDelete="confirmDelete(item)"
          @update:getById="getById(item)"
          v-for="item in listaCadencias.result"
          :key="item.id"
          :item="item"
        ></ticket-cadencia>
      </div>
      <pagination
        style="margin-bottom: 40px"
        v-if="!isCadenciasEmpty"
        :currentPage="page"
        :total="listaCadencias.length"
        @update:currentPage="nextPage"
      ></pagination>
    </div>
    <cria-cadencia
      v-else-if="currentRoute == 'create'"
      @update:voltar="voltar()"
    ></cria-cadencia>
    <detalhes-cadencia
      v-else
      :currentCadencia="currentCadence"
      @update:voltar="voltar"
    ></detalhes-cadencia>
  </div>
</template>

<style scoped>
.contedo-helpdesk-container1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-helpdesk-header {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contedo-helpdesk-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contedo-helpdesk-display {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contedo-helpdesk-text {
  color: var(--color-theme-primary);
  font-size: 45px;
  font-family: Fivo Sans Medium;
}
.contedo-helpdesk-actions {
  gap: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-helpdesk-pesquisa {
  flex: 0 0 auto;
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
}
.contedo-helpdesk-container4 {
  height: 40px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
  background-color: var(--dl-color-gray-white);
}
.contedo-helpdesk-container4 :hover {
  cursor: pointer;
}
.contedo-helpdesk-select {
  padding-left: 10px;
  padding-right: 10px;
}
.contedo-helpdesk-boto {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: 40px;
  border-radius: 10px;
  padding-right: 40px;
  padding-bottom: 0px;
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
}
.contedo-helpdesk-boto :hover {
  cursor: pointer;
}
.contedo-helpdesk-text01 {
  font-size: 15px;
}
.contedo-helpdesk-clientlist {
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media (max-width: 990px) {
  .contedo-helpdesk-container2 {
    flex-wrap: wrap;
  }
  .contedo-helpdesk-pesquisa {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .contedo-helpdesk-container2 {
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 35px;
  }
  .contedo-helpdesk-pesquisa {
    width: 100%;
  }
  .contedo-helpdesk-actions {
    width: 100%;
  }
  .contedo-helpdesk-boto {
    width: 60%;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .contedo-helpdesk-actions {
    flex-direction: column;
    align-items: flex-start;
  }
  .contedo-helpdesk-text {
    font-size: 30px;
  }
  .contedo-helpdesk-container4 {
    width: 100%;
    padding: 10px;
  }
  .contedo-helpdesk-boto {
    width: 100%;
  }
  .contedo-helpdesk-select {
    width: 100%;
  }
}
</style>