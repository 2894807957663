export default class Oportunity {
  constructor({
    id,
    name,
    email,
    phone,
    business_client_id,
    email_id,
    lead_id,
    inbox_id,
    score,
    call,
    qualified,
    createdAt,
    updatedAt,
    comments,
  } = {}) {
    this.id = id ?? null;
    this.nome = name ?? "";
    this.email = email ?? "";
    this.telefone = phone ?? "";
    this.empresa_id = business_client_id ?? null;
    this.email_id = email_id ?? null;
    this.lead_id = lead_id ?? null;
    this.inbox_id = inbox_id ?? null;
    this.score = score ?? null;
    this.call = call ?? null;
    this.qualified = qualified ?? null;
    this.created_date = createdAt ?? "";
    this.updated_date = updatedAt ?? "";
    this.comments = comments ?? "";
  }

  toBDOportunity() {
    return {
      name: this.nome,
      email: this.email,
      phone: this.telefone,
      score: this.score,
      call: this.call,
      qualified: this.qualified,
      comments: this.comments,
      business_client_id: this.empresa_id,
      createdAt: this.created_date
    };
  }
}
