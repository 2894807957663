import CadenceController from "@/controllers/CadenceController";

const cController = new CadenceController();

const cadenceStore = {
  namespaced: true,
  state: () => ({
    cadences: [],
    selectedCadence: null,
    selectedDeleteCadence: null,
    popupDelete: false,
  }),
  mutations: {
    SET_CADENCES(state, payload) {
      state.cadences = payload;
    },
    SET_SELECTED_CADENCE(state, payload) {
      state.selectedCadence = payload;
    },
    SET_DELETE_POPUP(state, payload) {
      state.popupDelete = payload;
    },
    SET_SELECTED_CADENCE(state, payload) {
      state.selectedDeleteCadence = payload;
    },
  },
  actions: {
    setDeleteCadence({ commit }, payload) {
      commit("SET_DELETE_POPUP", payload.value);
      commit("SET_SELECTED_CADENCE", payload.cadence);
    },
    async createCadence({ dispatch }, payload) {
      try {
        let cadence =  await cController.createCadence(payload);
        dispatch(
          "setDialog",
          {
            message: "Nova cadência criada com sucesso",
            icon: "mail-outline",
            type: "success",
          },
          { root: true }
        );
        return cadence;
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao criar uma nova cadência",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    async getAll({ commit, dispatch }) {
      commit('SET_LOADING', true, { root: true })
      try {
        const cadences = await cController.getAllCadences();
        commit("SET_CADENCES", cadences.data);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar todas as cadências",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
    async getCadenceById({ commit, dispatch }, payload) {
      try {
        const cadence = await cController.getCadenceById(payload);
        commit("SET_SELECTED_CADENCE", cadence);
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao pegar dados da cadência selecionada",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      }
    },
    async deleteCadence({ commit, dispatch }, payload) {
      commit('SET_LOADING', true, { root: true })
      try {
        await cController.deleteCadence(payload.cadence_id);
        dispatch('getAll')
        dispatch(
          "setDialog",
          {
            message: "Os dados da cadência foram deletados com sucesso!",
            type: "success",
            icon: "checkmark-circle-outline",
          },
          { root: true }
        );
      } catch (e) {
        dispatch(
          "setDialog",
          {
            message: "Erro ao deletar os dados da cadência selecionada",
            icon: "alert-circle-outline",
            type: "danger",
          },
          { root: true }
        );
      } finally {
        commit('SET_LOADING', false, { root: true })
      }
    },
  },
};

export default cadenceStore;
