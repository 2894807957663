export default class User {
  constructor({ name, email, status, uId, business_client_id, createdAt } = {}) {
    this.name = name ?? "";
    this.email = email ?? "";
    this.status = status ?? "INATIVO";
    this.uid = uId ?? "";
    this.empresa_id = business_client_id ?? '';
    this.createdAt = createdAt ?? "";
  }

  static toUser(data) {
    return new User({ ...data });
  }
}
