import BaseController from "./BaseController";

export default class LeadController extends BaseController {
  constructor() {
    super();
  }

  async getAllLeads() {
    return await super.getAll("leads").then((res) => {
      return res.data;
    });
  }

  async getLeadById(id) {
    return await super.getById("leads", id);
  }

  async updateLead(id, lead) {
    await super.update("leads", id, lead);
  }

  async deleteLead(id) {
    await super.delete("leads", id);
  }

  async addLeadCadence(lead) {
    await super.create("lead-cadences", lead);
  }

  async getAllCompanys() {
    return await super.getAll('companies');
  }
}
