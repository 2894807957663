<script setup>
import MenuLateral from "@/components/dashboard/menu/menu-lateral";
import menuSuperior from "@/components/dashboard/menu/menu-superior.vue";
import toastAlert from "@/components/common/toast-alert.vue";
import ListaOportunitys from "@/components/dashboard/admin/oportunitys/lista-oportunitys.vue";
import Datepicker from "vue3-datepicker";
import { pt } from 'date-fns/locale'
import Oportunity from "@/models/Oportunity";
import InputText from "@/components/common/InputText.vue";
import moment from "moment";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();
const selectedOportunity = ref({
  id: null,
  nome: "",
  email: "",
  telefone: "",
  comments: "",
  call: null,
  qualified: null,
  score: null,
  created_date: "",
});
const pickedDate = ref(
  selectedOportunity.value.created_date != ""
    ? selectedOportunity.value.created_date
    : new Date()
);
const empresas = computed(() => {
  return store.state.empresaModule.empresas;
});
const selectedEmpresa = ref({});
function getEmpresa(id) {
  return empresas.value.find((emp) => emp.id == id);
}
onMounted(() => {
  store.dispatch("empresaModule/getAllEmpresas");
});
function updateOportunity(value) {
  selectedOportunity.value = value;
  pickedDate.value = new Date(value.created_date);
  selectedEmpresa.value = getEmpresa(value.empresa_id);
}

const isVisible = computed(() => {
  return store.state.oportunityModule.popupCriarOportunity;
});

const loading = computed(() => {
  return store.state.empresaModule.loading;
});

const salvar = () => {
  if (
    selectedOportunity.value.id != null &&
    selectedOportunity.value.id != undefined
  ) {
    const opID = selectedOportunity.value.id;
    const oportunity = new Oportunity({
      name: selectedOportunity.value.nome,
      email: selectedOportunity.value.email,
      phone: selectedOportunity.value.telefone,
      comments: selectedOportunity.value.comments,
      business_client_id: selectedEmpresa.value.id,
      call: selectedOportunity.value.call,
      score: selectedOportunity.value.score,
      qualified: selectedOportunity.value.qualified,
      createdAt: moment(pickedDate.value).format(),
    }).toBDOportunity();
    store.dispatch("oportunityModule/editOportunity", {
      op: oportunity,
      id: opID,
    });
  } else {
    if (
      selectedOportunity.value.nome != "" &&
      selectedOportunity.value.email != "" &&
      selectedEmpresa.value.id != null
    ) {
      const oportunity = new Oportunity({
        name: selectedOportunity.value.nome,
        email: selectedOportunity.value.email,
        phone: selectedOportunity.value.telefone,
        comments: selectedOportunity.value.comments,
        business_client_id: selectedEmpresa.value.id,
        call: selectedOportunity.value.call,
        score: selectedOportunity.value.score,
        qualified: selectedOportunity.value.qualified,
        createdAt: moment(pickedDate.value).format(),
      }).toBDOportunity();
      store.dispatch("oportunityModule/createOportunity", oportunity);
    } else {
      store.dispatch("setDialog", {
        message:
          "Erro! Os campos nome, e-mail, empresa e passagem de bastão são obrigatórios.",
        type: "danger",
        icon: "alert-circle-outline",
      });
    }
  }
  clearData();
};
const clearData = () => {
  selectedOportunity.value = new Oportunity();
  selectedEmpresa.value = {};
  pickedDate.value = new Date();
  store.commit("oportunityModule/SET_POPUP_CRIAR_OPORTUNITY", false);
};
const setCall = (value) => {
  if (value == true) {
    selectedOportunity.value.call == true
      ? (selectedOportunity.value.call = null)
      : (selectedOportunity.value.call = true);
  }
  if (value == false) {
    selectedOportunity.value.call == false
      ? (selectedOportunity.value.call = null)
      : (selectedOportunity.value.call = false);
  }
};

const setQualificado = (value) => {
  if (value == true) {
    selectedOportunity.value.qualified = true;
  }
  if (value == false) {
    selectedOportunity.value.qualified = false;
  }
};

const setStar = (value) => {
  if (value != 0) {
    for (var i = 0; i < value; i++) {
      const star = document.getElementById(`estrela_${i}`);
      star.style = "color: var(--color-theme-primary)";
    }
  } else {
    for (var i = 0; i < 5; i++) {
      const star = document.querySelector(`#estrela_${i}`);
      star.style = "color: white";
    }
  }
};

const setScore = (value) => {
  selectedOportunity.value.score = value;
};
</script>

<template>
  <div class="growth-dashboard2-container">
    <toast-alert></toast-alert>
    <menu-lateral
      id="menu-lateral"
      rootClassName="menu-lateral-root-class-name6"
    ></menu-lateral>
    <menu-superior id="menu-superior"></menu-superior>
    <div class="content">
      <lista-oportunitys
        @update:selectedOportunity="updateOportunity"
      ></lista-oportunitys>
    </div>
    <div v-if="isVisible" class="modal">
      <div class="novo-chamado-pop-upintro">
        <div class="novo-chamado-barra">
          <span class="novo-chamado-novo-chamado">OPORTUNITY</span>
          <button @click="clearData">
            <img
              alt="image"
              src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
              class="novo-chamado-image"
            />
          </button>
        </div>
        <div class="novo-chamado-entrada">
          <div class="contedo-settings-box">
            <div class="contedo-settings-container02">
              <div class="contedo-settings-bottoncontent">
                <div class="contedo-settings-container05">
                  <div class="contedo-settings-container06">
                    <div class="contedo-settings-reuniao">
                      <div class="contedo-settings-cliente11">
                        <span class="contedo-settings-text16 Clientquestion">
                          Houve reunião?
                        </span>
                      </div>
                    </div>
                    <div
                      @click="setCall(false)"
                      type="call"
                      :selected="selectedOportunity.call"
                      :hasValue="selectedOportunity.call != null ? true : false"
                      class="contedo-settings-container-nao"
                    >
                      <div class="contedo-settings-container11">
                        <span class="contedo-settings-text21">
                          <span>NÃO</span>
                          <br />
                        </span>
                      </div>
                    </div>
                    <div
                      @click="setCall(true)"
                      type="call"
                      :selected="selectedOportunity.call"
                      :hasValue="selectedOportunity.call != null ? true : false"
                      class="contedo-settings-container-sim"
                    >
                      <div class="contedo-settings-container08">
                        <span class="contedo-settings-text17">
                          <span>SIM</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="contedo-settings-container09">
                    <div class="contedo-settings-reuniao1">
                      <div class="contedo-settings-cliente12">
                        <span class="contedo-settings-text20 Clientquestion">
                          Este lead é qualificado?
                        </span>
                      </div>
                    </div>
                    <div
                      @click="setQualificado(false)"
                      type="qualificado"
                      :selected="selectedOportunity.qualified"
                      :hasValue="
                        selectedOportunity.qualified == null ? true : false
                      "
                      class="contedo-settings-container-nao"
                    >
                      <div class="contedo-settings-container11">
                        <span class="contedo-settings-text21">
                          <span>NÃO</span>
                          <br />
                        </span>
                      </div>
                    </div>
                    <div
                      @click="setQualificado(true)"
                      type="qualificado"
                      :selected="selectedOportunity.qualified"
                      :hasValue="
                        selectedOportunity.qualified != null ? true : false
                      "
                      class="contedo-settings-container-sim"
                    >
                      <div class="contedo-settings-container088">
                        <span class="contedo-settings-text17">
                          <span>SIM</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="contedo-settings-leadclass">
                    <div class="contedo-settings-score">
                      <span class="contedo-settings-text24 Clientquestion">
                        Classificação do Lead
                      </span>
                      <div
                        v-if="selectedOportunity.score != null"
                        class="contedo-settings-container-estrelas"
                      >
                        <div v-for="i in selectedOportunity.score" :key="i">
                          <ion-icon
                            name="star"
                            class="contedo-settings-image07"
                            type="star-filled"
                          ></ion-icon>
                        </div>
                        <div v-for="i in 5 - selectedOportunity.score" :key="i">
                          <ion-icon
                            name="star"
                            class="contedo-settings-image07"
                            type="star"
                          ></ion-icon>
                        </div>
                      </div>
                      <div v-else class="contedo-settings-container-estrelas">
                        <ion-icon
                          @mouseover="setStar(1)"
                          @mouseleave="setStar(0)"
                          @click="setScore(1)"
                          name="star"
                          class="contedo-settings-image07"
                          type="null"
                          id="estrela_0"
                        ></ion-icon>
                        <ion-icon
                          @mouseover="setStar(2)"
                          @mouseleave="setStar(0)"
                          @click="setScore(2)"
                          name="star"
                          class="contedo-settings-image07"
                          type="null"
                          id="estrela_1"
                        ></ion-icon>
                        <ion-icon
                          @mouseover="setStar(3)"
                          @mouseleave="setStar(0)"
                          @click="setScore(3)"
                          name="star"
                          class="contedo-settings-image07"
                          type="null"
                          id="estrela_2"
                        ></ion-icon>
                        <ion-icon
                          @mouseover="setStar(4)"
                          @mouseleave="setStar(0)"
                          @click="setScore(4)"
                          name="star"
                          class="contedo-settings-image07"
                          type="null"
                          id="estrela_3"
                        ></ion-icon>
                        <ion-icon
                          @mouseover="setStar(5)"
                          @mouseleave="setStar(0)"
                          @click="setScore(5)"
                          name="star"
                          class="contedo-settings-image07"
                          type="null"
                          id="estrela_4"
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <InputText
                label="Nome e sobrenome"
                v-model:value="selectedOportunity.nome"
              ></InputText>
              <InputText
                label="Email"
                v-model:value="selectedOportunity.email"
              ></InputText>
              <InputText
                label="Telefone"
                v-model:value="selectedOportunity.telefone"
              ></InputText>
              <textarea
                class="observacoes-contato"
                v-model="selectedOportunity.comments"
                placeholder="Observações"
              ></textarea>
              <span class="label-text">Passagem de bastão: </span>
              <div class="select-container">
                <Datepicker
                  style="
                    --vdp-selected-bg-color: var(--color-theme-primary);
                    --vdp-hover-bg-color: var(--color-theme-primary);
                  "
                  input-format="dd-MM-yyyy"
                  v-model="pickedDate"
                  :locale="pt"
                  :weekStartsOn="0"
                  :clearable="true"
                />
              </div>
              <span class="label-text">Empresa: </span>
              <div class="select-container">
                <select v-model="selectedEmpresa">
                  <option
                    v-for="empresa in empresas"
                    :key="empresa.id"
                    :value="empresa"
                    :label="empresa.name"
                  >
                    {{ selectedEmpresa.name }}
                  </option>
                </select>
              </div>
              <div class="contedo-settings-button">
                <div class="contedo-settings-buttonset">
                  <div @click="salvar" class="contedo-settings-buttonaction">
                    <span class="contedo-settings-text12">{{
                      selectedOportunity.id != null &&
                      selectedOportunity.id != undefined
                        ? "Salvar alterações"
                        : "Criar oportunity"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true">
        <spinner-loading></spinner-loading>
      </div>
    </div>
  </div>
</template>

<style scoped>
.growth-dashboard2-container {
  width: 100%;
  display: flex;
  max-height: 100vh;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
}
#menu-lateral {
  gap: 30px;
  height: 100%;
  width: 300px;
  min-width: 300px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  padding-left: 10px;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-theme-primary);
  box-sizing: border-box;
  transition: all ease 0.3s;
}
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.novo-chamado-pop-upintro {
  width: 100%;
  height: 650px;
  margin: 0px;
  display: flex;
  position: relative;
  max-width: 800px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.novo-chamado-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.novo-chamado-novo-chamado {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  font-family: "Fivo Sans Medium";
}
.novo-chamado-image {
  top: 8px;
  right: 0px;
  width: 25px;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.novo-chamado-entrada {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  overflow-y: scroll;
}
.contedo-settings-box {
  gap: 10px;
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contedo-settings-container02 {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-bottoncontent {
  gap: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container05 {
  gap: 5px;
  width: 100%;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
}
.contedo-settings-container06 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-settings-reuniao {
  flex: 0 0 auto;
  width: 70%;
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #eeeeee;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-cliente11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text16 {
  line-height: 1;
}
.contedo-settings-container-sim {
  gap: 5px;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contedo-settings-container-sim[hasValue="false"] :hover {
  background-color: #00a89c;
}
.contedo-settings-container-sim[selected="true"]
  .contedo-settings-container088 {
  background-color: #00a89c;
}
.contedo-settings-container-sim[selected="true"] .contedo-settings-container08 {
  background-color: #00a89c;
}
.contedo-settings-container08 {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: rgb(180, 180, 180);
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-container088 {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  justify-content: center;
  background-color: rgb(180, 180, 180);
}
.contedo-settings-text17 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  text-align: center;
  font-family: "Fivo Sans Medium";
  line-height: 1;
}
.contedo-settings-container09 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.contedo-settings-reuniao1 {
  flex: 0 0 auto;
  width: 70%;
  height: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #e3e3e3;
  border-top-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-cliente12 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contedo-settings-text20 {
  line-height: 1;
}
.contedo-settings-container-nao {
  gap: 5px;
  width: 15%;
  display: flex;
  align-items: center;
  background-color: rgb(180, 180, 180);
  justify-content: space-between;
}
.contedo-settings-container-nao[hasValue="false"] :hover {
  background-color: var(--color-theme-primary);
}
.contedo-settings-container-nao[selected="false"] {
  background-color: var(--color-theme-primary);
}
.contedo-settings-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.contedo-settings-text21 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  align-self: center;
  text-align: center;
  font-family: "Fivo Sans Medium";
  line-height: 1;
}
.contedo-settings-leadclass {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--color-theme-primary);
  padding-left: 20px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: #eeeeee;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.contedo-settings-score {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contedo-settings-text24 {
  align-self: center;
  text-align: left;
}
.contedo-settings-container-estrelas {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.contedo-settings-image07 {
  width: 22px;
  object-fit: cover;
  font-size: 18px;
}
.contedo-settings-image07[type="star"] {
  color: white;
}
.contedo-settings-image07[type="star-filled"] {
  color: var(--color-theme-primary);
}
.contedo-settings-image07[type="null"] {
  color: white;
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.observacoes-contato {
  color: var(--color-main);
  width: 100%;
  outline: none;
  height: fit-content;
  min-height: 100px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-theme-primary);
}
.label-text {
  font-size: 18px;
  font-family: Fivo Sans Medium;
  color: rgb(115, 115, 115);
}
.select-container {
  height: 40px;
  width: fit-content;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 10px;
  padding: 3px;
}
.observacoes-contato:focus {
  border: 1px solid var(--color-theme-primary);
}
.contedo-settings-button {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}
.contedo-settings-buttonset {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contedo-settings-buttonaction {
  flex: 0 0 auto;
  width: 70%;
  height: 50px;
  display: flex;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: var(--color-theme-primary);
}
.contedo-settings-buttonaction :hover {
  cursor: pointer;
}
.contedo-settings-text12 {
  color: #ffffff;
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Fivo Sans Medium;
}
#timeline {
  visibility: hidden;
  display: none;
}
@media (max-width: 1440px) {
  .contedo-settings-box {
    position: relative;
  }
}
@media (max-width: 1040px) {
  .contedo-settings-box {
    width: 80%;
  }
}
@media (max-width: 950px) {
  .contedo-settings-box {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .growth-dashboard2-container {
    flex-direction: column;
  }
  #menu-lateral {
    visibility: hidden;
    display: none;
  }
}
@media (max-width: 657px) {
  .contedo-settings-box {
    padding: 15px;
  }
  #timeline {
    visibility: visible;
    display: flex;
  }
  .contedo-settings-container06 {
    flex-direction: column;
  }
  .contedo-settings-reuniao {
    padding-bottom: 0;
    border-top-right-radius: 10px;
    width: 100%;
  }
  .contedo-settings-container-sim {
    width: 100%;
  }
  .contedo-settings-container08 {
    border-top-right-radius: 0;
    padding: 10px;
  }
  .contedo-settings-container09 {
    flex-direction: column;
  }
  .contedo-settings-reuniao1 {
    width: 100%;
  }
  .contedo-settings-container-nao {
    width: 100%;
  }
  .contedo-settings-container11 {
    padding: 10px;
  }
  .contedo-settings-leadclass {
    height: fit-content;
  }
  .contedo-settings-score {
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .contedo-settings-button {
    width: 100%;
  }
  .contedo-settings-buttonset {
    width: 100%;
  }
  .contedo-settings-buttonaction {
    width: 100%;
  }
}
</style>
