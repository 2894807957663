import BaseController from "./BaseController";

export default class CompanyController extends BaseController {
  constructor() {
    super()
  }

  async createCompany(company) {
    await super.create('companies', company)
  }

  async getAllCompany() {
    return await super.getAll('companies')
  }

  async getCompanyById(id) {
    return await super.getById('companies', id)
  }

  async updateCompany(id, company) {
    await super.update('companies', id, company)
  }
}
