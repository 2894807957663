import BaseController from "./BaseController";

export default class EmpresaController extends BaseController {
  constructor() {
    super()
  }

  async createEmpresa(empresa) {
    await super.create('business-clients', empresa)
  }

  async getAllEmpresas() {
    return await super.getAll('business-clients')
  }

  async getEmpresaById(id) {
    return await super.getById('business-clients', id)
  }

  async updateEmpresa(id, empresa) {
    await super.update('business-clients', id, empresa)
  }

  async getAllPlans() {
    return await super.getAll('plans')
  }
}
