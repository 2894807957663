import BaseController from "./BaseController";

export default class OportunityController extends BaseController {
  constructor() {
    super()
  }

  async createOportunity(oportunity) {
    await super.create('oportunities', oportunity)
  }

  async getAllOportunitys() {
    return await super.getAll('oportunities').then((res) => {
      return res
    })
  }

  async getOportunityById(id) {
    return await super.getById('oportunities', id)
  }

  async getOportunityByEmpresaId(id) {
    return await super.getById('oportunities/business-client', id)
  }

  async updateOportunity(id, Oportunity) {
    await super.update('oportunities', id, Oportunity)
  }
}
