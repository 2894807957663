<script setup>
import InputText from "@/components/common/InputText.vue";
import { ref, inject } from "vue";
import { useStore } from "vuex";

const primaryColor = inject('primaryColor').replace('#', '')

const store = useStore();

const isVisible = ref(false);
const popup = ref(0);
const data = ref({
  nome_empresa: '',
  publico_ideal: '',
  produto: '',
  valor_ticket: '',
  pessoas: '',
  agendamentos_atuais: '',
  empresa: '',
  nao_comparecimento: '',
  vendas_finais: '',
  vendas_mensais: '',
  tempo: '',
});

const togglePopup = () => {
  isVisible.value = !isVisible.value;
};

const next = () => {
  popup.value++;
};

const next2 = () => {
  saveData(data.value);
  popup.value = 0;
  isVisible.value = false;
};

const saveData = (payload) => {
  if (Object.keys(payload).length > 0 ) {
    try {
      /* axios.post(
        "https://6523fefeea560a22a4e93628.mockapi.io/empresa_info",
        payload
      ); */
      store.dispatch('setDialog', {
        message: "Formulário enviado com sucesso!",
        type: "success",
        icon: "document",
      })
    } catch (e) {
      store.dispatch('setDialog', {
        message: "Erro ao enviar o formulário, tente novamente mais tarde.",
        type: "danger",
        icon: "alert-circle-outline",
      })
      console.log(e);
    }
  }
};
</script>

<template>
  <div class="modal" v-if="isVisible">
    <div class="pop-up" v-if="isVisible && popup == 0" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-texto">
        <h1 class="pop-up-text">Olá! seja bem-vindo, Nome !</h1>
        <span class="pop-up-text1">
          <span>
            Antes de começarmos, vamos precisar de algumas informações
            importantes sobre o ramo de atuação da sua empresa, informações
            sobre os seus produtos e o potencial público alvo para alimentar a
            nossa inteligência e assim maximizar os resultados e agendamentos. É
            bem rápido!
          </span>
          <br />
          <br />
          <span>Vamos começar?</span>
          <br />
        </span>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text7">Responder questionário</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 1" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container04">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container06">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container08">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container10">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <div class="pop-up-container12">
          <h2 class="pop-up-text">Qual o nome da empresa?</h2>
          <input-text label="Empresa" v-model:value="data.nome_empresa"></input-text>
        </div>
        <div class="pop-up-container13">
          <h2 class="pop-up-text1">Qual o público ideal?</h2>
          <input-text v-model:value="data.publico_ideal" label="Publico ideal"></input-text>
        </div>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text2">Próximo</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 2" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
        <div class="pop-up-container04">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container06">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container08">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container10">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <div class="pop-up-container12">
          <h2 class="pop-up-text">Qual produto é o mais vendido?</h2>
          <input-text v-model:value="data.produto" label="Produto"></input-text>
        </div>
        <div class="pop-up-container13">
          <h2 class="pop-up-text1">Qual o valor do ticket médio de vocês?</h2>
          <input-text v-model:value="data.valor_ticket" label="Valor"></input-text>
        </div>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text2">Próximo</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 3" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
        <div class="pop-up-container06">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container08">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container10">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <div class="pop-up-container12">
          <h2 class="pop-up-text">
            Quantas pessoas do seu time hoje são responsáveis pela prospecção de
            clientes?
          </h2>
          <input-text v-model:value="data.pessoas" label="Número de pessoas"></input-text>
        </div>
        <div class="pop-up-container13">
          <h2 class="pop-up-text1">
            Quantos agendamentos com possíveis clientes vocês fazem mensalmente?
          </h2>
          <input-text v-model:value="data.agendamentos_atuais" label="Total de agendamentos"></input-text>
        </div>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text2">Próximo</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 4" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container04">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
        <div class="pop-up-container08">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container10">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <div class="pop-up-container12">
          <h2 class="pop-up-text">
            Desses agendamentos, qual a taxa de não comparecimento?
          </h2>
          <input-text v-model:value="data.nao_comparecimento" label="Número médio de desistências"></input-text>
        </div>
        <div class="pop-up-container13">
          <h2 class="pop-up-text1">Quantas vendas você efetua mensalmente?</h2>
          <input-text v-model:value="data.vendas_mensais" label="Média de vendas"></input-text>
        </div>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text2">Próximo</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 5" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container04">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container06">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
        <div class="pop-up-container10">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <div class="pop-up-container12">
          <h2 class="pop-up-text">
            Quantas vendas você quer efetuar mensalmente?
          </h2>
          <input-text v-model:value="data.vendas_finais" label="Vendas mensais esperadas"></input-text>
        </div>
        <div class="pop-up-container13">
          <h2 class="pop-up-text1">
            Em quanto tempo você deseja aumentar essas vendas?
          </h2>
          <input-text v-model:value="data.tempo" label="Tempo em dias, meses ou anos"></input-text>
        </div>
      </div>
      <div class="pop-up-boto">
        <button @click="next" type="button" class="pop-up-button">
          <span class="pop-up-text2">Próximo</span>
        </button>
      </div>
    </div>
    <div class="pop-up" v-if="isVisible && popup == 6" v-bind:class="String">
      <div class="pop-up-barra">
        <button @click="togglePopup">
          <img
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="pop-up-image"
          />
        </button>
      </div>
      <div class="pop-up-primaryColor">
        <img
          alt="image"
          src="https://res.cloudinary.com/dd9puomgu/image/upload/v1695736645/dzhofokf4nj4kbtplv3x.svg"
          class="pop-up-image1"
        />
      </div>
      <div class="pop-up-steps">
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container02">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container04">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container06">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-container08">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}&width=19&height=19`"
          />
        </div>
        <div class="pop-up-currentStep">
          <img
            :src="`https://api.iconify.design/bxs/circle.svg?color=%23${primaryColor}`"
          />
        </div>
      </div>
      <div class="pop-up-texto">
        <h2 class="pop-up-text">
          Existe alguma empresa que não podemos prospectar?
        </h2>
        <input-text v-model:value="data.empresa" label="Empresa"></input-text>
      </div>
      <div class="pop-up-boto">
        <button @click="next2" type="button" class="pop-up-button">
          <span class="pop-up-text2">Enviar respostas</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;
}
.pop-up {
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  position: fixed;
  max-width: 800px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  max-height: 600px;
  min-height: 600px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.pop-up-barra {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: flex-end;
  background-color: var(--color-theme-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.pop-up-image {
  width: 25px;
  margin-right: 10px;
}
.pop-up-primaryColor {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pop-up-image1 {
  width: 289px;
  height: 51px;
  object-fit: cover;
}
.pop-up-steps {
  gap: 10px;
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding: 20px 0 20px 0;
  justify-content: center;
}
.pop-up-currentStep {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-color: var(--color-theme-primary);
  border-width: 2px;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container01 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-container02 {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container03 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-container04 {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container05 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-container06 {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container07 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-container08 {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container09 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-container10 {
  flex: 0 0 auto;
  width: 15;
  height: 15;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.pop-up-container11 {
  flex: 0 0 auto;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: flex-start;
  border-radius: 100%;
  background-color: var(--color-theme-primary);
}
.pop-up-texto {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  padding: 0px 30px 30px 50px;
  flex-direction: column;
  justify-content: center;
}
.pop-up-container12 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pop-up-text {
  font-family: "Fivo Sans Medium";
}
.pop-up-textinput {
  width: 100%;
}
.pop-up-container13 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pop-up-text1 {
  font-family: "Fivo Sans Medium";
}
.pop-up-textinput1 {
  width: 100%;
}
.pop-up-boto {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  justify-content: center;
}
.pop-up-button {
  color: #ffffff;
  height: 50px;
  display: flex;
  transition: 0.3s;
  font-family: Fivo Sans Medium;
  border-color: var(--color-theme-primary);
  border-width: 0px;
  padding-left: 20px;
  border-radius: 7px;
  padding-right: 20px;
  flex-direction: row;
  background-color: var(--color-theme-primary);
}
.pop-up-button:hover {
  color: var(--color-theme-primary);
  border-width: 1px;
  background-color: var(--dl-color-gray-white);
}

.pop-up-text7 {
  font-size: 20px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.pop-up-text7:hover {
  color: var(--color-theme-primary);
}
.pop-up-text2 {
  font-size: 20px;
  align-self: center;
  margin-top: 1px;
  transition: 0.3s;
  align-items: center;
  margin-left: 5px;
}
.pop-up-text2:hover {
  color: var(--color-theme-primary);
}

@media (max-width: 957px) {
  .pop-up {
    max-height: 800px;
  }
  .pop-up-pop-up {
    margin: 0px;
    display: flex;
  }
}
@media (max-width: 767px) {
  .pop-up {
    max-height: 800px;
  }
  .pop-up-pop-up {
    display: flex;
  }
}
@media (max-width: 479px) {
  .pop-up {
    max-height: 800px;
  }
  .pop-up-pop-up {
    display: flex;
    max-height: 800px;
  }
}
</style>